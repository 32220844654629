import dynamic from "next/dynamic";
const ListingBCard = dynamic(() =>
  import("./ListingBCard/ListingBCard.controller")
);
const ImgTxtCardAView = dynamic(() =>
  import("@/mvcComponents/v2/Commons/Cards/ImgTxtCardA/ImgTxtCardA.view")
);
const ImgTxtCardBView = dynamic(() =>
  import("@/mvcComponents/v2/Commons/Cards/ImgTxtCardB/ImgTxtCardB.view")
);
const ImgDetailsAView = dynamic(() =>
  import("@/mvcComponents/v2/Commons/Cards/ImgDetailsA/ImgDetailsA.view")
);
const ImgDetailsBView = dynamic(() =>
  import("@/mvcComponents/v2/Commons/Cards/ImgDetailsB/ImgDetailsB.view")
);
const PersonDCardView = dynamic(() =>
  import("@/mvcComponents/v2/Commons/Cards/PersonDCard/PersonDCard.view")
);

const CardsMap = {
  IMAGE_TEXT_CARD_A: ImgTxtCardAView,
  IMAGE_TEXT_CARD_B: ImgTxtCardBView,
  IMAGE_DETAILS_A: ImgDetailsAView,
  IMAGE_DETAILS_B: ImgDetailsBView,
  PERSON_DETAILS_D: PersonDCardView,
  LISTING_B: ListingBCard,
};

export default CardsMap;

import CardBuilderController from "@/mvcComponents/CommonUI/Cards/CardBuilder.controller";
import React from "react";
import CourseSectionForCmsView from "../Commons/UtilityComponents/CourseSectionForCms.view";
import DynmCrdsWrapperView from "../Commons/UtilityComponents/DynmCrdsWrapper.view";
import styles from "./DynmCrds.module.css";

const DynmCrdsView = ({
  heading,
  subHeading,
  headingAlignment = "center",
  section_id,
  isCardClickable,
  cards,
  boxShadowType,
  separatorType,
  borderRadius,
  showOn,
}) => {
  const boxShadowClass =
    !boxShadowType || boxShadowType === "none" ? "" : boxShadowType;

  const outerDivStyle = borderRadius
    ? { borderRadius: `${borderRadius}px` }
    : {};

  return (
      <div
        className={`${styles["cards-wrapper"]} ${boxShadowClass}`}
        style={outerDivStyle}
      >
        <CardBuilderController
          isCardClickable={cards.isCardClickable}
          cardDetails={cards}
          section_id={section_id}
          boxShadowType={boxShadowType}
          separatorType={separatorType}
        />
      </div>
  );
};

const WrappedDynmCrds = DynmCrdsWrapperView(DynmCrdsView);

export default WrappedDynmCrds;

export const CMSDynmCrdsView = CourseSectionForCmsView(WrappedDynmCrds);

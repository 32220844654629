import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useUserActions from "@/store/actions/UserProfileActions/userAction";

const ShowUnsubscribedComponent = ({
  children,
  skipCourseDetailCheck = false,
}) => {
  const { getActiveCourseSubscription, user } = useUserActions();

  let condition =
    getActiveCourseSubscription(user) &&
    (skipCourseDetailCheck ||
      UserCourseService?.instance?.course?.slug == undefined ||
      UserCourseService?.instance?.course?.slug == "subscription-course");

  return (user || !user) && condition ? null : children;
};

export default ShowUnsubscribedComponent;

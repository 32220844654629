/**
 * Converts a width value in various units (px, rem, em) to pixels.
 * @param {string} width - The width value to convert (e.g., "18px", "10rem", "2em").
 * @returns {number} - The width value in pixels.
 */
export const convertToPixels = (width) => {
  const match = width.match(/^(\d+\.?\d*)(px|rem|em)?$/);
  if (!match) return 24;

  const value = parseFloat(match[1]);
  const unit = match[2] || "px";

  switch (unit) {
    case "rem":
      return value * 16;
    case "em":
      return value * 16;
    case "px":
    default:
      return value;
  }
};

export const joinClassNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

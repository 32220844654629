import React from "react";
import CardsMap from "./CardBuilder.model";
import { joinClassNames } from "@/utils/cssHelper";
import styles from "./CardBuilder.module.css";

const CardBuilderController = ({ cardDetails, section_id, separatorType }) => {
  const {
    name,
    cardType,
    cards,
    cardWidth = "250px",
    cardHeight,
    isCardClickable,
    mobileCardWidth,
    imgPosition,
  } = cardDetails;

  const CardComponent = CardsMap[cardType];

  const separatorClass =
    !separatorType || separatorType === "none" ? "" : separatorType;

  return (
    <>
      {CardComponent &&
        cards?.map((card, index) => {
          const { id, __component, ...props } = card;

          return (
            <React.Fragment key={id}>
              <CardComponent
                id={id}
                isCardClickable={isCardClickable}
                {...props}
                width={props.width ?? cardWidth}
                height={props.height ?? cardHeight}
                mobileCardWidth={props.mobileCardWidth ?? mobileCardWidth}
                section_id={props.section_id ?? section_id}
                imgPosition={props.imgPosition ?? imgPosition}
              />

              {separatorClass && index !== cards.length - 1 && (
                <div
                  className={joinClassNames(
                    separatorClass,
                    styles["separator"]
                  )}
                ></div>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default CardBuilderController;

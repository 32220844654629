import React from "react";
import ShowUnsubscribedComponent from "./ShowUnsubscribedComponent.view";

const CourseSectionForCmsView = (Component) => {
  return function EnhancedComponent(props) {
    return (
      <ShowUnsubscribedComponent>
        <section className="UI-V2 " id={props.section_id}>
          <div className="section-page-course">
            <Component {...props} />
          </div>
        </section>
      </ShowUnsubscribedComponent>
    );
  };
};

export default CourseSectionForCmsView;

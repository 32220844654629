import React from "react";
import styles from "./DynmCrdsWrapper.module.css";
import ConditionLinkView from "./ConditionLink.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const DynmCrdsWrapperView = (Component) => {
  return function EnhancedComponent(props) {
    let {
      ctaLink = "",
      ctaText = "",
      section_id = "",
      heading = "",
      subHeading = "",
      headingAlignment = "center",
      showOn = [],
    } = props || {};
    return (
      <UseComponentVisibility conditions={showOn}>
        <div className="section-counter" id={section_id}>
          <div className="tf-container">
            {(heading || subHeading) && (
              <div className={`row ${headingAlignment}`}>
                <div className="col-12">
                  <div className="heading-section text-center">
                    <h2
                      className="fw-7 font-cardo wow fadeInUp"
                      data-wow-delay="0s"
                    >
                      {heading}
                    </h2>
                    <div
                      className="sub fs-14 wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      {subHeading}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Component {...props} />
            {ctaLink && ctaText && (
              <ConditionLinkView
                link={ctaLink}
                section_id={section_id}
                analytics={"dynamic-cards"}
              >
                <div className={styles.cta}>{ctaText}</div>
              </ConditionLinkView>
            )}
          </div>
        </div>
      </UseComponentVisibility>
    );
  };
};

export default DynmCrdsWrapperView;
